import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../assets/descaling.sass"
import Image from "../../components/image"
import Carousel from 'react-bootstrap/Carousel'

const SeasonalandPermanent = () => (
  <div className="descaling-component">
    
    <SEO title="Seasonal and Permanent" />

    <div className="service-header">
      <h1>Seasonal and Permanent</h1>
    </div>

    <div className="descaling-statements descaling-statements-red">
      <p>
      Navigate the changing seasons with ease through our comprehensive seasonal and permanent services. From rejuvenating pressure washing and meticulous carpet cleaning to essential home renovations and repairs, 
      we provide tailored solutions to keep your home pristine and functional year-round. 
      Embrace each season with confidence, knowing our dedicated team is here to handle all your maintenance, 
      landscaping, and remodeling needs with unmatched expertise and care.
      </p>
    </div>

    <div className="desktop-image-video">

      <Carousel>
            <div className="scoping-images">
              <Image filename="seasonal.jpeg"/>
            </div>
 
      </Carousel>

    </div>
    
    <div className="descaling-statements desktop-statements">    
      <ul className="sewer-services-list">
        <li>Pressure Washing</li>
        <li>Carpet Cleaning</li>
        <li>Spring Cleaning and Maintenance</li>
        <li>Summer Landscaping and Garden Upkeep</li>
        <li>Fall Gutter Cleaning and Leaf Removal</li>
        <li>Winterizing</li>
        <li>Foundation and Structural Repairs</li>
        <li>Home Renovation and Remodeling</li>
        <li>Interior Roof Repairs</li>
      </ul>
    </div>

  </div>
)

export default SeasonalandPermanent
